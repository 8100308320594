import * as React from 'react';
import { App } from '../../../../pkg';
import classname from 'classnames';
import { CursorDrawMode } from '../../../models/cursor';

import './index.scss';

import CursorImage from '../../../assets/icons/cursor.svg';
import OvalImage from '../../../assets/icons/oval.svg';
import RectangleImage from '../../../assets/icons/rectangle.svg';
import TriangleImage from '../../../assets/icons/triangle.svg';
import SaveImage from '../../../assets/icons/save.svg';
import TextImage from '../../../assets/icons/text.svg';
import PencilImage from '../../../assets/icons/pencil.svg';
import ImageImage from '../../../assets/icons/image.svg';

interface IToolbarProps {
  app: App;
  currentMode: CursorDrawMode;
  onChangeMode: (mode: CursorDrawMode) => void;
}

const Toolbar: React.FC<IToolbarProps> = ({ app, currentMode, onChangeMode }: IToolbarProps) => {
  return (
    <div className="toolbar">
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Cursor,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Cursor);
        }}
      >
        <CursorImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Rectangle,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Rectangle);
        }}
      >
        <RectangleImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Oval,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Oval);
        }}
      >
        <OvalImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Triangle,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Triangle);
        }}
      >
        <TriangleImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Text,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Text);
        }}
      >
        <TextImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Image,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Image);
        }}
      >
        <ImageImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action', {
          active: currentMode === CursorDrawMode.Pencil,
        })}
        onClick={() => {
          onChangeMode(CursorDrawMode.Pencil);
        }}
      >
        <PencilImage viewBox="0 0 128 128" />
      </div>
      <div
        className={classname('action')}
        onClick={() => {
          app.save();
        }}
      >
        <SaveImage viewBox="0 0 128 128" />
      </div>
    </div>
  );
};

export default Toolbar;
