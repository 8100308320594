import { EventEmitter } from 'events';

class WasmEventReceiver extends EventEmitter {}

let receiver: EventEmitter | null = null;

export function registEventReceiver(cb: () => void) {
  if (receiver) {
    throw new Error('Has been registered.');
  }
  receiver = new WasmEventReceiver();
  window.onWasmEvent = evt => {
    console.log(evt);
    cb();
  };
}
