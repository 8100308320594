export function registerResize(): string {
  const throttle = function (type: string, name: string, obj: Window) {
    let running = false;
    const func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  const customEventName = 'optimizedResize;';

  /* init - you can init any event */
  throttle('resize', customEventName, window);

  return customEventName;
}
