export enum CursorDrawMode {
  Cursor = 0,
  Vector,
  Rectangle,
  Oval,
  Triangle,
  Text,
  Pencil,
  Image,
}
