import { EventEmitter } from 'events';

/**
 * calculate fps
 */
export default class FPS extends EventEmitter {
  private frames: number[];
  private lastFrameTimeStamp: number;
  constructor() {
    super();
    this.frames = [];
    this.lastFrameTimeStamp = performance.now();
  }

  render() {
    const now = performance.now();
    const delta = now - this.lastFrameTimeStamp;
    this.lastFrameTimeStamp = now;
    const fps = (1 / delta) * 1000;

    this.frames.push(fps);
    if (this.frames.length > 100) {
      this.frames.shift();
    }
    this.emit('update');
  }

  public measure(): { latest: number; avg: number; min: number; max: number } {
    let min = Infinity;
    let max = -Infinity;
    let sum = 0;

    for (let i = 0; i < this.frames.length; i++) {
      sum += this.frames[i];
      min = Math.min(this.frames[i], min);
      max = Math.max(this.frames[i], max);
    }

    const mean = sum / this.frames.length;
    return {
      latest: Math.round(this.frames[this.frames.length - 1]),
      avg: Math.round(mean),
      min: Math.round(min),
      max: Math.round(max),
    };
  }
}
