export const SupportImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

function initUploadDom(): HTMLInputElement {
  const dom = document.createElement('input');
  dom.type = 'file';
  dom.style.position = 'absolute';
  dom.style.zIndex = '0';
  dom.style.left = '-1000px';
  dom.style.top = '-1000px';
  dom.style.width = '10px';
  dom.style.height = '10px';
  return dom;
}

/**
 * 获取图片file对象
 */
export async function selectImage(): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    const dom = initUploadDom();
    dom.accept = SupportImageTypes.join(',');
    dom.multiple = false;
    dom.onchange = e => {
      const el: HTMLInputElement = e.target as HTMLInputElement;
      if (el.files && el.files.length) {
        const file: File = el.files[0];
        resolve(file);
      } else {
        reject('no image selected.');
      }
      dom.parentElement?.removeChild(dom);
    };
    dom.click();
  });
}
