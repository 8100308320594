import * as React from 'react';
import classnames from 'classnames';

import { IPropertyBarProps } from '../type';
import { Style } from '../../../../models/style';

const Min = 1;
const Max = 1000;

const getTextSize = (size: number): number => Math.max(Min, Math.min(Max, size));

const TextProperties = ({ app }: IPropertyBarProps) => {
  const style = app.get_selection_style() as Style;
  const [textSize, setTextSize] = React.useState(style.text_size.toString());
  return (
    <div className="prop-items-group">
      <div className={classnames('prop-item', 'prop-text-size')}>
        <label htmlFor="text-size">Text Size:</label>
        <input
          id="text-size"
          type="number"
          value={textSize}
          onChange={e => {
            setTextSize(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              app.update_selection_style({
                text_size: getTextSize(parseInt(textSize, 10)),
              });
            }
          }}
          onBlur={() => {
            app.update_selection_style({
              text_size: getTextSize(parseInt(textSize, 10)),
            });
          }}
        />
      </div>
      <div className={classnames('prop-item', 'prop-fill-color')}>
        <label htmlFor="fill-color">Text Color:</label>
        <input
          id="fill-color"
          type="color"
          value={style.fill_color.to_rgb_hex()}
          onChange={e => {
            app.update_selection_style({
              fill_color: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default TextProperties;
