import * as React from 'react';
import { App } from '../../../../pkg';

import ShapeProperties from './ShapeProperties';
import TextProperties from './TextProperties';
import { IPropertyBarProps } from './type';

import './index.scss';

const renderProperties = (selectionType: string, app: App) => {
  switch (selectionType) {
    case 'Text':
      return <TextProperties app={app} />;
    case 'Shape':
      return <ShapeProperties app={app} />;
    default:
      return null;
  }
};

const PropertyBar = ({ app }: IPropertyBarProps) => {
  const selectionType = app.get_selection_type();
  return <div className="propertybar top">{renderProperties(selectionType, app)}</div>;
};

export default PropertyBar;
