import { Color } from '../../pkg';

export enum FillType {
  None,
  Hachure,
  Solid,
  Mosaic,
}

export enum StrokeType {
  None,
  Solid,
}

export interface Style {
  fill_color: Color;
  fill_type: FillType;
  stroke_color: Color;
  stroke_type: StrokeType;
  text_size: number;
}
