import * as React from 'react';

import './index.scss';

import Toolbar from './Toolbar';
import PropertyBar from './PropertyBar';
import { App } from '../../../pkg';
import { registerResize } from '../../utils/dom-events';
import { makeLooper } from '../../utils/loop';
import Fps from './Fps';
import Textarea from './Textarea';
import { CursorDrawMode } from '../../models/cursor';
import { registEventReceiver } from '../../wasmEventReceiver';
import { selectImage } from '../../utils/file';

interface IApplicationProps {
  app: App;
  memory: WebAssembly.Memory;
}

enum MouseEventType {
  Down,
  Up,
  Move,
  Click,
}

function getCanvasSizeInfo(): {
  width: number;
  height: number;
  dpr: number;
} {
  const body_width = window.document.body.clientWidth;
  const body_height = window.document.body.clientHeight;
  const dpr = window.devicePixelRatio || 1;
  return {
    width: body_width - 40,
    height: body_height - 40,
    dpr,
  };
}

const resetAppSize = (app: App): void => {
  const sizeInfo = getCanvasSizeInfo();
  app.handle_resize(sizeInfo.width, sizeInfo.height, sizeInfo.dpr);
};

const Application: React.FC<IApplicationProps> = ({ app, memory }: IApplicationProps) => {
  const looper = makeLooper(app);
  const [currentMode, setCurrentMode] = React.useState<CursorDrawMode>(CursorDrawMode.Cursor);
  const [version, setVersion] = React.useState(0);
  const [editText, setEditText] = React.useState<null | {
    left: number;
    top: number;
    text: string;
  }>(null);

  React.useEffect(() => {
    resetAppSize(app);
    looper.start();
    registEventReceiver(() => {
      setTimeout(() => {
        const mode = app.get_cursor_draw_mode();
        setCurrentMode(mode);
      }, 0);
    });

    const handleResize = () => {
      resetAppSize(app);
    };
    const customResizeEventName = registerResize();
    window.addEventListener(customResizeEventName, handleResize);
    return () => {
      window.removeEventListener(customResizeEventName, handleResize);
    };
  }, [app]);

  React.useEffect(() => {
    const finishTextEditing = () => {
      if (editText && editText.text.trim()) {
        app.create_text_node(editText.text.trim(), editText.left - 40, editText.top - 40);
      }
      setEditText(null);
      app.change_cursor_draw_mode(CursorDrawMode.Cursor);
    };

    const handleMouseEvent = (eventType: MouseEventType, updateVersion: boolean, event: MouseEvent) => {
      if (event.target instanceof Element && event.target.tagName === 'TEXTAREA') {
        return;
      }
      const x = event.clientX - 40;
      const y = event.clientY - 40;
      if (x < 0 || y < 0) {
        return;
      }
      if (currentMode === CursorDrawMode.Text) {
        if (eventType === MouseEventType.Down) {
          if (!editText) {
            setEditText({
              left: event.clientX,
              top: event.clientY,
              text: '',
            });
          } else {
            finishTextEditing();
          }
        }
        return;
      }
      app.handle_mouse(eventType, x, y, {
        shift: event.shiftKey,
        alt: event.altKey,
        ctrl: event.metaKey,
      });
      if (updateVersion) {
        setVersion(Math.random());
      }
    };

    const handleClick = handleMouseEvent.bind(null, MouseEventType.Click, true);
    document.addEventListener('click', handleClick);

    const handleMouseDown = handleMouseEvent.bind(null, MouseEventType.Down, false);
    document.addEventListener('mousedown', handleMouseDown);

    const handleMouseMove = handleMouseEvent.bind(null, MouseEventType.Move, false);
    document.addEventListener('mousemove', handleMouseMove);

    const handleMouseUp = handleMouseEvent.bind(null, MouseEventType.Up, true);
    document.addEventListener('mouseup', handleMouseUp);

    const handleKeyDown = (e: KeyboardEvent) => {
      // eslint-disable-next-line
      // @ts-ignore
      const tagName = e.target?.tagName;
      if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
        return;
      }
      if (currentMode === CursorDrawMode.Text) {
        if (e.key === 'Escape') {
          finishTextEditing();
        }
        return;
      }
      let captured = true;
      if (e.metaKey) {
        switch (e.key) {
          case 'a':
            app.select_all();
            break;
          case 'g':
            app.group_selection();
            break;
          case 's':
            app.save();
            break;
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
            app.set_viewport(e.key);
            break;
          case 'z':
            if (e.shiftKey) {
              app.handle_redo();
            } else {
              app.handle_undo();
            }
            break;
          case 'c':
                app.handle_copy();
            break;
          case 'x':
                app.handle_cut();
            break;
          case 'v':
                app.handle_paste();
            break;
          default:
            captured = false;
            break;
        }
      } else {
        switch (e.key) {
          case 'Backspace':
            app.handle_delete();
            break;
          case 'r':
            app.change_cursor_draw_mode(CursorDrawMode.Rectangle);
            break;
          case 'o':
            app.change_cursor_draw_mode(CursorDrawMode.Oval);
            break;
          case 't':
            app.change_cursor_draw_mode(CursorDrawMode.Text);
            break;
          case 'Escape':
            app.handle_escape_key();
            break;
          default:
            captured = false;
            break;
        }
      }
      if (captured) {
        e.stopPropagation();
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    const handleWheel = (event: WheelEvent) => {
      if (currentMode === CursorDrawMode.Text) {
        return;
      }
      if (event.metaKey) {
        const x = event.pageX - 40;
        const y = event.pageY - 40;
        app.handle_zoom(event.deltaY, x, y);
      } else {
        app.handle_wheel(-event.deltaX, -event.deltaY);
      }
    };
    document.addEventListener('wheel', handleWheel, {
      passive: false,
    });

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      document.removeEventListener('keydown', handleKeyDown);

      document.removeEventListener('wheel', handleWheel);
    };
  }, [currentMode, editText, app]);

  return (
    <div className="application">
      <div className="left">
        <Toolbar
          app={app}
          currentMode={currentMode}
          onChangeMode={mode => {
            if (mode === CursorDrawMode.Image) {
              // app.create_image_node();
              selectImage().then(imageFile => {
                const fr = new FileReader();
                fr.onload = () => {
                  const arrayBuffer = fr.result as ArrayBuffer;
                  app.allocate(arrayBuffer.byteLength);
                  const wasmBuffer = new Uint8Array(memory.buffer);
                  const start = app.memory_pos();
                  wasmBuffer.set(new Uint8Array(arrayBuffer), start);
                  app.create_image();
                };
                // fr.readAsDataURL(imageFile);
                fr.readAsArrayBuffer(imageFile);
              });
            } else {
              app.change_cursor_draw_mode(mode);
            }
          }}
        />
      </div>
      <div className="right">
        <PropertyBar app={app} version={version} />
      </div>
      <Fps fps={looper.fps} />
      {editText && (
        <Textarea
          text={editText.text}
          left={editText.left}
          top={editText.top}
          onChangeText={text => {
            setEditText(Object.assign({}, editText, { text }));
          }}
        />
      )}
    </div>
  );
};

export default Application;
