import * as React from 'react';

import FPS from '../../../utils/fps';
import './index.scss';

interface IFpsProps {
  fps: FPS;
}

const Fps = (props: IFpsProps) => {
  const [measure, setMeasure] = React.useState(props.fps.measure());
  React.useEffect(() => {
    props.fps.on('update', () => {
      setMeasure(props.fps.measure());
    });
  }, []);
  return (
    <ul className="fps">
      <li>FPS: {measure.latest}</li>
      <li>AVG: {measure.avg}</li>
      <li>MIN: {measure.min}</li>
      <li>MAX: {measure.max}</li>
    </ul>
  );
};

export default React.memo(Fps);
