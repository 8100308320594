import * as React from 'react';

import './index.scss';

interface ITextareaProps {
  left: number;
  top: number;
  text: string;
  onChangeText: (text: string) => void;
}

const Textarea: React.FC<ITextareaProps> = (props: ITextareaProps) => {
  const textRef = React.useRef<HTMLTextAreaElement | null>(null);
  React.useEffect(() => {
    if (textRef) {
      textRef.current?.focus();
      textRef.current?.select();
    }
  }, []);
  return (
    <textarea
      className="textarea"
      ref={textRef}
      style={{
        left: props.left,
        top: props.top,
      }}
      value={props.text}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.onChangeText(e.target.value);
        if (textRef && textRef.current) {
          textRef.current.style.height = 'auto';
          textRef.current.style.height = textRef.current.scrollHeight + 'px';
        }
      }}
    />
  );
};

export default Textarea;
