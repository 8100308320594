import { App } from '../../pkg';
import FPS from './fps';

interface Looper {
  fps: FPS;
  start: () => void;
}

export const makeLooper = (app: App): Looper => {
  const fps = new FPS();
  const loop = () => {
    if (app.should_rerender()) {
      fps.render();
      app.render();
      app.set_un_changed();
    }
    window.requestAnimationFrame(loop);
  };
  const looper = {
    fps,
    start: function () {
      window.requestAnimationFrame(loop);
    },
  };
  return looper;
};
