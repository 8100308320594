import * as React from 'react';
import classnames from 'classnames';

import { IPropertyBarProps } from '../type';
import { Style, FillType, StrokeType } from '../../../../models/style';

const ShapeProperties = ({ app }: IPropertyBarProps) => {
  const style = app.get_selection_style() as Style;
  return (
    <>
      <div className="prop-items-group">
        <div className={classnames('prop-item', 'prop-fill-type')}>
          <label htmlFor="fill-type">Fill Type:</label>
          <select
            id="fill-type"
            value={style.fill_type}
            onChange={e => {
              app.update_selection_style({
                fill_type: parseInt(e.target.value, 10),
              });
            }}
          >
            <option value={FillType.None}>None</option>
            <option value={FillType.Hachure}>Hachure</option>
            <option value={FillType.Solid}>Solid</option>
            <option value={FillType.Mosaic}>Mosaic</option>
          </select>
        </div>
        <div
          className={classnames('prop-item', 'prop-fill-color', {
            'prop-item-disabled': style.fill_type === FillType.None,
          })}
        >
          <label htmlFor="fill-color">Fill Color:</label>
          <input
            id="fill-color"
            type="color"
            disabled={style.fill_type === FillType.None}
            value={style.fill_color.to_rgb_hex()}
            onChange={e => {
              app.update_selection_style({
                fill_color: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="prop-items-group">
        <div className={classnames('prop-item', 'prop-stroke-type')}>
          <label htmlFor="stroke-type">Stroke Type:</label>
          <select
            id="stroke-type"
            value={style.stroke_type}
            onChange={e => {
              app.update_selection_style({
                stroke_type: parseInt(e.target.value, 10),
              });
            }}
          >
            <option value={StrokeType.None}>None</option>
            <option value={StrokeType.Solid}>Solid</option>
          </select>
        </div>
        <div
          className={classnames('prop-item', 'prop-stroke-color', {
            'prop-item-disabled': style.stroke_type === StrokeType.None,
          })}
        >
          <label htmlFor="stroke-color">Stroke Color:</label>
          <input
            type="color"
            value={style.stroke_color.to_rgb_hex()}
            disabled={style.stroke_type === StrokeType.None}
            onChange={e => {
              app.update_selection_style({
                stroke_color: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ShapeProperties;
