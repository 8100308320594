// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L6AHsnbydhRSbFDQ6_JR{position:fixed;left:0;bottom:0;width:100%;height:14px;background:#fff;text-align:center;z-index:1}.L6AHsnbydhRSbFDQ6_JR a{color:#000;font-size:12px;line-height:14px}", "",{"version":3,"sources":["webpack://./www/components/Footer/index.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,MAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,SAAA,CACA,wBACI,UAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".footer {\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    height: 14px;\n    background: white;\n    text-align: center;\n    z-index: 1;\n    a {\n        color: black;\n        font-size: 12px;\n        line-height: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "L6AHsnbydhRSbFDQ6_JR"
};
export default ___CSS_LOADER_EXPORT___;
