import { App } from '../../pkg';

async function loadFont(fileName: string): Promise<ArrayBuffer> {
  return fetch(`/assets/fonts/${fileName}`).then(resp => resp.arrayBuffer());
}

export async function loadFonts(app: App, memory: WebAssembly.Memory): Promise<void> {
  const promises = [
    // roboto
    loadFont('Roboto-Regular.ttf'),
    // hand draw English
    loadFont('Blokletters-Viltstift.ttf'),
    // hand draw Chinese
    // loadFont('zh_hand_draw.ttf')
  ];

  const fonts = await Promise.all(promises);
  console.log(fonts);
  for (let i = 0; i < fonts.length; i++) {
    console.log(i);
    const font = fonts[i];
    app.allocate(font.byteLength);
    const wasmBuffer = new Uint8Array(memory.buffer);
    const start = app.memory_pos();
    wasmBuffer.set(new Uint8Array(font), start);
    app.load_font();
  }
}
