import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { memory } from '../pkg/index_bg.wasm';
import { loadFonts } from './utils/font';

import './index.scss';
import Application from './components/Application';
import Footer from './components/Footer';

import('../pkg')
  .then(({ App }) => {
    const app = App.new();

    loadFonts(app, memory)
      .then(() => {
        app.load_saved_data();
        ReactDOM.render(<Application app={app} memory={memory} />, document.getElementById('application'));
      })
      .catch(e => {
        console.error(e);
      });
  })
  .catch(console.error);

const el = document.createElement('div');
document.body.appendChild(el);
ReactDOM.render(<Footer />, el);

